@font-face {
  font-family: 'EB Garamond';
  src: url('./fonts/EB_Garamond/EB_Garamond/static/EBGaramond-Regular.ttf')
    format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Bodoni';
  src: url('./fonts/Bodoni/Bodoni/OpenType/Bodoni-11-Medium.otf');
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat/Montserrat/Montserrat-Regular.ttf')
    format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Over the Rainbow';
  src: url('./fonts/Over_the_Rainbow/Over_the_Rainbow/OvertheRainbow-Regular.ttf')
    format('truetype');
  font-display: swap;
}

body {
  font-family: 'Montserrat';
}
